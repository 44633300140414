<template>
  <div class="assignment">
    <br />
    <span class="heading">Create / </span
    ><span class="heading" style="color: #642c90">Create Assignment</span>
    <hr style="color: #e0e0e0 !important" />
    <div class="card-panel">
      <div class="row">
        <div class="col l3 s12 assignment-filter">
          <span class="heading fs-16" style="color: #686868">Course</span>
          <select
            class="browser-default dropd"
            @change="onCourseChange($event)"
            v-model="selectCourse"
          >
            <option selected disabled>-- SELECT --</option>
            <option :value="data.courseId" v-for="data in Courses" :key="data.affiliationCourseId">
              {{ data.courseName }}
            </option>
          </select>
          <div v-if="courseValidation" style="color: red">
            <span>Please select Course.</span>
          </div>
        </div>
        <div class="col l3 s12 assignment-filter">
          <span class="heading fs-16" style="color: #686868">Batch</span>
          <div>
            <VueMultiselect
              class="dropd"
              v-model="AffiliationBatch"
              :options="listOfBatchesForSelectedCourse"
              placeholder="Select one"
              @update:model-value="updateSelectedBatch"
              label="BatchName"
              track-by="BatchId"
              :multiple="true"
              :limit="1"
              :close-on-select="false"
              @tag="BatchTag"
            >
              <template v-slot:beforeList>
                <div class="row" style="margin-top: 5px">
                  <a class="multiselectBtn waves-effect waves-light btn" @click="selectBatchNone"
                    ><span style="display: flex; justify-content: center">None</span></a
                  >&nbsp;
                  <a class="multiselectBtn waves-effect waves-light btn" @click="selectBatchAll"
                    ><span>All </span></a
                  >
                </div>
              </template>
            </VueMultiselect>
          </div>
          <div v-if="batchValidation" style="color: red">
            <span>Please select Batch.</span>
          </div>
        </div>
        <div class="col l3 s12 assignment-filter">
          <span class="heading fs-16" style="color: #686868">Class</span>
          <select
            class="browser-default dropd"
            @change="onClassChange($event)"
            v-model="selectClass"
          >
            <option selected disabled>-- SELECT --</option>
            <option :value="classn.id" v-for="classn in classList" :key="classn.id">
              {{ classn.displayName }}
            </option>
          </select>
          <div v-if="classValidation" style="color: red">Please select Class.</div>
        </div>
        <div class="col l3 s12 assignment-filter">
          <span class="heading fs-16" style="color: #686868">Subject</span>
          <select
            class="browser-default dropd"
            @change="onSubjectChange($event)"
            v-model="selectSubject"
          >
            <option selected disabled>-- SELECT --</option>
            <option
              :value="subject.subjectId"
              v-for="subject in listOfSubjectsForSelectedCourse"
              :key="subject.subjectId"
            >
              {{ subject.subjectName }}
            </option>
          </select>
          <div v-if="subjectValidation" style="color: red">Please select Subject.</div>
        </div>
        <!-- <div class="col l3 s12 assignment-filter">
                          <span class="heading">Topic</span>
                          <select class="browser-default dropd " v-model="selectTopic" @change="onTopicChange($event)">
                              <option selected disbaled>-- SELECT --</option>
                              <option :value="topic.TopicId" v-for="topic in topiclist" :key="topic.TopicName">
                                  {{ topic.TopicName }}
                              </option>
                          </select>
                      </div> -->
      </div>
      <div class="row">
        <div class="col l3 s12 assignment-filter">
          <span class="heading fs-16" style="color: #686868">Chapter</span>
          <select
            class="browser-default dropd"
            v-model="selectChapter"
            @change="onChapterChange($event)"
          >
            <option selected disabled>-- SELECT --</option>
            <option
              :value="chapter.CourseChapterId"
              v-for="chapter in chapterlist"
              :key="chapter.CourseChapterId"
            >
              {{ chapter.ChapterName }}
            </option>
          </select>
          <div v-if="chapterValidation" style="color: red">Please select Chapter.</div>
        </div>
        <div class="col l3 s12 assignment-filter">
          <span class="heading fs-16" style="color: #686868">Cluster</span>
          <!-- <select class="browser-default dropd " v-model="selectKscCluster" @change="onClusterChange($event)">
                              <option selected disbaled>-- SELECT --</option>
                              <option :value="cluster.KSCClusterId" v-for="cluster in kscClusterList" :key="cluster.DisplayRank">
                                  {{ cluster.KSCClusterName }}
                              </option>
                          </select> -->
          <div>
            <VueMultiselect
              class="dropd"
              v-model="AffiliationKsc"
              :options="kscClusterList"
              placeholder="Select one"
              @update:model-value="updateSelectedKsc"
              label="KSCClusterName"
              track-by="KSCClusterId"
              :multiple="true"
              :limit="1"
              :close-on-select="false"
              @tag="KscTag"
            >
              <template v-slot:beforeList>
                <div class="row" style="margin-top: 5px">
                  <a class="multiselectBtn waves-effect waves-light btn" @click="selectKscNone"
                    ><span style="display: flex; justify-content: center">None</span></a
                  >&nbsp;
                  <a class="multiselectBtn waves-effect waves-light btn" @click="selectKscAll"
                    ><span>All </span></a
                  >
                </div>
              </template>
            </VueMultiselect>
          </div>
          <div v-if="clusterValidation" style="color: red">Please select Cluster.</div>
        </div>
        <div class="col l3 s12 assignment-filter">
          <span class="heading fs-16" style="color: #686868">Assignment Type</span>
          <select
            class="browser-default dropd"
            @change="onAssignmentChange($event)"
            v-model="selectAssignmentTypeId"
          >
            <option :value="0" selected>-- SELECT --</option>
            <option :value="1">Common Assignment</option>
            <option :value="2">PDF Assignment</option>
            <!-- <option :value="3">Auto Assignment</option> -->
          </select>
          <div v-if="assignmentValidation" style="color: red">Please select Assignment Type.</div>
          <div v-if="commonAssiValidation" style="color: red">
            Please select Questions for Common Assignment.
          </div>
          <div v-if="fileValidation" style="color: red">Please uplaod a Pdf File.</div>
        </div>
        <div class="col l3 s12" v-if="selectAssignmentTypeId === 1 || selectAssignmentTypeId === 2">
          <span style="opacity: 0">Assignment Selection Button</span>
          <button class="ques-btn" @click="goToModal()">
            {{
              selectAssignmentTypeId === 2
                ? "Upload a PDF"
                : selectAssignmentTypeId === 1
                ? "Select Common Question"
                : ""
            }}
          </button>
          <div v-if="this.isFileUploaded === true" style="color: green">
            {{ this.uploadedFileName }} file uploaded
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: 12px">
        <button class="batch-button" @click="CreateAssignment()">Create Assignment</button>
      </div>
    </div>
    <div class="row">
      <table class="qtbl striped">
        <thead class="data-table-head">
          <tr>
            <th>Chapter</th>
            <th>Course</th>
            <th>Batch</th>
            <th>Assignment Type</th>
            <th>Professor</th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :value="assignment.AssignmentLogId"
            v-for="assignment in this.getassignmentlist"
            :key="assignment.AssignmentLogId"
          >
            <td style="width: 15%; font-weight: 500">{{ assignment.ChapterName }}</td>
            <td style="width: 10%; font-weight: 500">{{ assignment.CourseName }}</td>
            <td style="width: 15%; font-weight: 500">{{ assignment.Batches }}</td>
            <td style="width: 15%; font-weight: 500">{{ assignment.AssignmentType }}</td>
            <td style="width: 10%; font-weight: 500">{{ assignment.Faculty }}</td>
            <td
              style="width: 15%; color: #642c90; font-weight: 600"
              v-if="!assignment.IsPublished && assignment.AssignmentType !== 'Auto Assignment'"
            >
              Will Publish on {{ formatDate(assignment.StartDate)
              }}{{ formatTime(assignment.StartDate) }}
            </td>
            <td
              style="width: 15%; color: #642c90; font-weight: 600"
              v-if="
                assignment.IsPublished &&
                assignment.StartDate > currentDate &&
                assignment.AssignmentType !== 'Auto Assignment'
              "
            >
              Will Publish on {{ formatDate(assignment.StartDate)
              }}{{ formatTime(assignment.StartDate) }}
            </td>
            <td
              style="width: 15%; color: #642c90; font-weight: 600"
              v-if="
                assignment.IsPublished &&
                assignment.StartDate < currentDate &&
                assignment.AssignmentType !== 'Auto Assignment'
              "
            >
              Published on {{ formatDate(assignment.StartDate)
              }}{{ formatTime(assignment.StartDate) }}
            </td>
            <td
              style="width: 10%; color: #642c90; font-weight: 600"
              v-if="assignment.AssignmentType === 'Auto Assignment'"
            >
              Assignment Published
            </td>
            <td v-if="!assignment.IsPublished && assignment.AssignmentType !== 'Auto Assignment'">
              <button @click="OpenPublishModal(assignment.AssignmentLogId)" class="publish-button">
                Publish
              </button>
            </td>
            <td
              v-if="
                assignment.IsPublished &&
                assignment.StartDate > currentDate &&
                assignment.AssignmentType !== 'Auto Assignment'
              "
            >
              <button
                @click="OpenRescheduleModal(assignment.AssignmentLogId)"
                class="reschedule-button"
              >
                Reschedule
              </button>
            </td>
            <td
              v-if="
                assignment.IsPublished &&
                assignment.StartDate <= currentDate &&
                assignment.AssignmentType !== 'Auto Assignment'
              "
            >
              <button class="published-button">Published</button>
            </td>
            <td v-if="assignment.AssignmentType === 'Auto Assignment'">
              <button class="published-button">Published</button>
            </td>
            <td style="display: flex, justify-content: center">
              <img
                v-if="assignment.AssignmentType !== 'Auto Assignment'"
                :src="require(`../assets/eye.svg`)"
                @click="onViewAssignment(assignment.AssignmentLogId)"
              />&nbsp;<i
                v-if="!assignment.IsPublished && assignment.AssignmentType !== 'Auto Assignment'"
                class="material-icons"
                style="color: red"
                @click="cancelModal(assignment.AssignmentLogId)"
                >cancel</i
              >
            </td>
          </tr>
          <!-- <button class="publish-button" @click="OpenPublishModal()">Publish</button> -->
          <!-- <button class="published-button">Published</button> -->
          <!-- <button class="reschedule-button">Reschedule</button> -->
        </tbody>
      </table>
    </div>
    <Modal
      :show="showCampaignModal"
      @close="showCampaignModal = false"
      :showHeader="false"
      :showCloseBtn="true"
      :width="'60%'"
    >
      <template v-slot:body class="assignment-modal-body">
        <div class="row">
          <span class="heading" style="color: #642c90">Publish Assignment</span>
        </div>
        <div class="row">
          <div class="col s12 m6">
            <label style="font-size: 0.8rem; display: block">Start DateTime</label>
            <v-date-picker
              v-model="startDate"
              mode="dateTime"
              :min-date="minDate"
              :minute-increment="5"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  class="browser-default"
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                />
              </template>
            </v-date-picker>
            <br />
            <br />
          </div>
          <div class="col s12 m6">
            <label style="font-size: 0.8rem; display: block">End DateTime</label>
            <v-date-picker
              v-model="endDate"
              mode="dateTime"
              :min-date="minDate"
              :max-date="maxDate"
              :minute-increment="5"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  class="browser-default"
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                />
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="row">
          <h6 class="col" style="color: #37bc16">
            Choose Student For Assignment of the Selected Batches From the DropDown*
          </h6>
        </div>
        <div class="row">
          <div class="col l4 s12 assignment-filter">
            <select
              class="browser-default dropd"
              @change="onSelectedBatchChange($event)"
              @click="onBatchSelect($event)"
              v-model="selectClass"
            >
              <option selected>-- SELECT --</option>
              <option
                :value="batch.BatchId"
                v-for="batch in this.SelectedBatch"
                :key="batch.BatchId"
              >
                {{ batch.Name }}
              </option>
            </select>
            <div v-if="batchValidation" style="color: red">Please select Batch.</div>
            <div>
              <VueMultiselect
                class="browser-default dropd"
                v-model="StudentCsv"
                :options="studentList"
                placeholder="Select one"
                @update:model-value="updateSelectedStudent"
                label="Name"
                track-by="UserId"
                :multiple="true"
                :limit="1"
                :close-on-select="false"
                @tag="StudentTag"
              >
                <template v-slot:beforeList>
                  <div class="row" style="margin-top: 5px">
                    <a
                      class="multiselectBtn waves-effect waves-light btn"
                      @click="selectStudentNone"
                      ><span style="display: flex; justify-content: center">None</span></a
                    >&nbsp;
                    <a class="multiselectBtn waves-effect waves-light btn" @click="selectStudentAll"
                      ><span>All </span></a
                    >
                  </div>
                </template>
              </VueMultiselect>
            </div>
            <div v-if="studentValidation" style="color: red">Please select Students.</div>
          </div>
        </div>
        <div class="row button-row">
          <div class="col">
            <button class="cancel-button" @click="cancelPublish()">Cancel</button>&ensp;&ensp;
            <button
              @click="PublishTheAssignment()"
              style="cursor: pointer"
              class="reschedule-button"
            >
              Publish
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showCampaignModal3"
      @close="showCampaignModal3 = false"
      :showHeader="false"
      :showCloseBtn="true"
    >
      <template v-slot:body class="auto-modal-body">
        <!-- <div class="row">
                      <span style="color:lightgreen; font-weight:600;">Assignment Saved Successfully</span>
                  </div> -->
        <div class="row">
          <span style="color: grey"
            >Click Create Assignment to Create and Publish Auto Assignment</span
          >
        </div>
        <hr />
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <button class="reschedule-button" @click="showCampaignModal3 = false">Okay</button>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showCampaignModal2"
      @close="showCampaignModal2 = false"
      :showHeader="false"
      :showCloseBtn="false"
    >
      <template v-slot:body class="auto-modal-body">
        <div class="modal-header document-upload-header">
          <span style="font-size: 20px; padding: 5px; font-weight: 600"
            >Choose the Assignment File</span
          >
        </div>
        <br />
        <div>
          <input id="file1" type="file" accept="application/pdf" @change="onUploadFile($event)" />
        </div>
        <div v-if="fileValidation" style="color: red">
          <span>Please Upload a PDF File</span>
        </div>
        <br />
        <br />
        <div style="display: flex; justify-content: space-around; width: 200px">
          <button class="reschedule-button pointer" @click="createStandardAssignment()">
            Upload</button
          ><button class="reschedule-button pointer" @click="cancelPublish()">Cancel</button>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showCommonAssignmentModal"
      @close="showCommonAssignmentModal = false"
      :showHeader="false"
      :width="'100%'"
      :height="'100%'"
      :maxHeightBody="'800px'"
    >
      <template v-slot:body class="assignment-modal-body">
        <!-- <div class="modal-header document-upload-header">
                    <span style="font-size: 20px;padding:5px;font-weight:600;">Select the Question For Common Assignment</span>
                    <span style="font-size: 20px;padding:5px;font-weight:600;">Question Selected: {{selectedCommonQuestions.length}}</span>
                </div> -->
        <div class="modalHeader flex flex-between" style="border-bottom: 1px solid #e0e4f0">
          <div>
            <h6 style="font-size: 18px; font-weight: 700; padding-left: 28px">
              Select the Question For Common Assignment
            </h6>
          </div>
          <div class="flex flex-between">
            <img
              :src="require(`../assets/modalClose.svg`)"
              style="padding-top: 5px; padding-right: 15px; padding-bottom: 10px; cursor: pointer"
              @click="closeAssignmentPopop()"
            />
          </div>
        </div>
        <div class="flex flex-between" style="padding-left: 28px; padding-right: 25px">
          <div>
            <h6 style="padding-bottom: 5px; font-size: 15px; font-weight: 600">
              Total Questions: {{ this.commonQuestionList.length }}
            </h6>
          </div>
          <div>
            <h6 style="padding-bottom: 5px; font-size: 15px; font-weight: 600">
              Question Selected: {{ selectedCommonQuestions.length }}
            </h6>
          </div>
          <!-- <div style="margin-top: 11px; margin-bottom: 7px;"><button class="loadMoreQuestionsButton" @click="loadMoreQuestions()">Load More Questions</button></div> -->
        </div>
        <div style="display: flex; gap: 25px; padding-left: 20px">
          <div class="filterSection">
            <h6
              style="
                padding-left: 15px;
                padding-bottom: 15px;
                font-size: 16px;
                font-weight: 700;
                border-bottom: 1px solid #e0e4f0;
                margin-bottom: 0px;
              "
            >
              Filter
            </h6>
            <div>
              <div
                class="flex"
                style="
                  width: 100%;
                  justify-content: space-between;
                  padding-left: 15px;
                  padding-right: 10px;
                  padding-top: 20px;
                  margin-bottom: 10px;
                  border-bottom: 1px solid rgb(224, 228, 240);
                  padding-bottom: 15px;
                "
              >
                <span
                  class="flex question-header flex-right nowrap"
                  style="font-size: 13px; font-weight: 700"
                  >All Questions</span
                >
                <label class="switch">
                  <input type="checkbox" @click="toggleCheckbox1()" :checked="this.checkbox1" />
                  <div class="slider round"></div>
                </label>
                <span class="question-header nowrap" style="font-size: 13px; font-weight: 700">
                  Assignment Qs</span
                >
              </div>
            </div>
            <div class="commonFilterComponent">
              <CommonFilterComponent
                :entityDataObject="this.conceptObject"
                :isCluster="true"
                :isChapter="false"
                :ChapterList="chapterList"
                @selectedEntityId="getSelectedEntityId($event)"
              />
            </div>
            <DifficultLevelComponent
              :selectedClusterIdQuestionslist="selectedClusterIdQuestions"
              @selectedDiffLevel="getCommonQDiffLevel($event)"
            />
            <!-- <div class="flex items-center" style="margin-left: 15px;">
                            <label class="pointer dark-text fs-13 fw-700" for="commonassignmentQuestions" style="padding-right: 38px;">Assignment Questions</label>
                            <input type="checkbox" v-model="assignmentQuestions" :checked="assignmentQuestions" id="commonassignmentQuestions" @change="commonAssignmentQuestions()" class="pointer" style="top: 3px; width: 17px; height: 17px;">
                        </div> -->
          </div>
          <div class="w-100">
            <div
              style="text-align: center; max-width: 850px; width: 100%"
              class="flex items-center"
              v-if="commonQuestionList.length === 0"
            >
              <span
                class="material-icons material-symbols-outlined red-text"
                style="font-size: 60px"
                >error</span
              >
              <p
                class="fs-18 red-text fw-600"
                style="margin-left: 10px !important; text-align: left"
                v-if="errorMsg === ''"
              >
                No assignment questions available please switch to all questions
              </p>
              <p
                class="fs-18 red-text fw-600"
                style="margin-left: 10px !important; text-align: left"
                v-if="errorMsg !== ''"
              >
                {{ errorMsg }}
              </p>
            </div>
            <div class="scroll-verticle-bar" v-if="commonQuestionList.length > 0">
              <ManualQuestion
                v-for="item in commonQuestionList"
                :key="item.QuestionId"
                :selectedDiffGrp="
                  item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'
                "
                :Difficulty="item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'"
                :QuestionDetail="item"
                @click="selectQns(item)"
                :isQustionSelected="
                  selectedCommonQuestions.includes(item.QuestionId) ? true : false
                "
              />
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showCampaignModal4"
      @close="showCampaignModal4 = false"
      :showHeader="false"
      :showCloseBtn="true"
      :width="'80%'"
    >
      <template v-slot:body class="assignment-modal-body">
        <div v-if="this.pdfURL.length > 0">
          <div class="modal-header document-upload-header">
            <span style="font-size: 20px; padding: 5px; font-weight: 600"
              >View the Uploaded Assignment</span
            >
          </div>
          <br />
          <!-- <a :href="this.pdfURL">Click the Link to Download the Uploaded File</a> -->
          <iframe
            width="100%"
            style="height: 500px"
            :src="`data:application/pdf;base64,${encodeURI(fileContent)}`"
          ></iframe>
        </div>
        <div class="questions-container" v-if="this.pdfURL.length === 0">
          <div class="modal-header document-upload-header">
            <span style="font-size: 20px; padding: 5px; font-weight: 600"
              >View the Selected Question For Assignment</span
            >
            <span style="font-size: 20px; padding: 5px; font-weight: 600"
              >Selected Questions: {{ questionURL.length }}</span
            >
          </div>
          <div
            class="card waves-effect"
            v-for="question in this.questionURL"
            :key="`q${question.QuestionId}`"
            @click="toggleQuestion(question.QuestionId)"
          >
            <div class="card-image">
              <img :src="`${question.QuestionDiagramURL}`" />
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showCampaignModal5"
      @close="showCampaignModal5 = false"
      :showHeader="false"
      :showCloseBtn="true"
    >
      <template v-slot:body class="reschedule-modal-body">
        <div class="row">
          <span class="heading" style="color: #642c90">Reschedule Assignment</span>
        </div>
        <div class="row">
          <div class="col s12 m6">
            <label style="font-size: 0.8rem; display: block">Start DateTime</label>
            <v-date-picker
              v-model="startDate"
              mode="dateTime"
              :min-date="minDate"
              :minute-increment="5"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  class="browser-default"
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                />
              </template>
            </v-date-picker>
            <br />
            <br />
          </div>
          <div class="col s12 m6">
            <label style="font-size: 0.8rem; display: block">End DateTime</label>
            <v-date-picker
              v-model="endDate"
              mode="dateTime"
              :min-date="minDate"
              :max-date="maxDate"
              :minute-increment="5"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  class="browser-default"
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                />
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="row button-row">
          <div class="col">
            <button class="cancel-button" @click="cancelPublish()">Cancel</button>&ensp;&ensp;
            <button @click="RescheduleAssignment()" class="reschedule-button">Reschedule</button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showCampaignModal6"
      @close="showCampaignModal6 = false"
      :showHeader="false"
      :showCloseBtn="true"
      style="overflow-y: none !important"
    >
      <template v-slot:body class="cancel-modal-body">
        <div class="row">
          <span class="heading" style="color: #642c90">Cancel Assignment</span>
        </div>
        <div class="row">
          <span class="heading">Do you want to cancel the assignment ?</span>
        </div>
        <div class="button-row" style="margin-top: 50px !important">
          <div class="col">
            <button class="cancel-button" @click="cancelPublish()">No</button>&ensp;&ensp;
            <button @click="cancelassign(cancelAssignId)" class="reschedule-button">Yes</button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import moment from "moment";
import API from "../Api";
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";
import ManualQuestion from "../components/ManualQuestion.vue";
// import FilterComponent1 from "../components/FilterComponent1.vue";
import DifficultLevelComponent from "../components/DifficultLevelComponent";
import CommonFilterComponent from "../components/CommonFilterComponent.vue";

export default {
  data() {
    return {
      selectedClusters: [],
      currentDate: "",
      selectedQuestionId: 0,
      Courses: [],
      courseBatchList: [],
      classList: [],
      listOfBatchesForSelectedCourse: [],
      listOfSubjectsForSelectedCourse: [],
      topiclist: [],
      chapterlist: [],
      kscClusterList: [],
      AffiliationBatch: [],
      AffiliationKsc: [],
      StudentCsv: [],
      commonQuestionClusterList: [],
      selectedCommonQuestions: [],
      getassignmentlist: [],
      studentList: [],
      SelectedBatch: [],
      BatchStudentIdCsv: [],
      file: "",
      addedAssignment: "",
      selectSubject: null,
      selectBatch: 0,
      selectCourse: null,
      selectClass: null,
      selectTopic: 0,
      selectChapter: 0,
      selectKscCluster: 0,
      pdfassignmentId: 0,
      selectAssignmentTypeId: -1,
      minDate: moment().startOf("day").toDate(),
      maxDate: moment().add(3, "months").toDate(),
      startDate: moment().add(15, "minutes").toDate(),
      endDate: moment().add(30, "minutes").toDate(),
      assstartDate: "",
      assendDate: "",
      showCampaignModal: false,
      showCommonAssignmentModal: false,
      showCampaignModal2: false,
      showCampaignModal3: false,
      showCampaignModal4: false,
      showCampaignModal5: false,
      showCampaignModal6: false,
      PdfId: null,
      pdfURL: {},
      questionURL: [],
      KSC: [],
      publishassignment: [],
      courseValidation: false,
      batchValidation: false,
      classValidation: false,
      subjectValidation: false,
      chapterValidation: false,
      clusterValidation: false,
      assignmentValidation: false,
      commonAssiValidation: false,
      studentValidation: false,
      fileValidation: false,
      fileContent: "",
      teacherDashAssigData: null,
      teacherDashClassId: null,
      cancelAssignId: null,
      selectBatchId: null,
      studentId: "",
      csvLength: null,
      previousBatchId: null,
      dataObj: {},
      btnText: "",
      commonQuestionList: [],
      commonAssignmentDifficultyGrp: [],
      //   commonQuestionClusterList: [],
      hideAll: "",
      commonQsDifficultyList: [],
      selectedDifficultyLevel: "",
      diffLevelFilterByClusterId: [],
      selectedCommonQuestionCluster: [],
      diffiGrpData: [
        {
          DifficultyGroup: "Basic",
          DifficultyGroupId: 0,
          color: "#FFFFFF",
          isSelected: false,
        },
        {
          DifficultyGroup: "Easy",
          DifficultyGroupId: 0,
          color: "#36AF8D",
          isSelected: false,
        },
        {
          DifficultyGroup: "Normal",
          DifficultyGroupId: 0,
          color: "#EDBF6B",
          isSelected: false,
        },
        {
          DifficultyGroup: "Hard",
          DifficultyGroupId: 0,
          color: "#EA7B7B",
          isSelected: false,
        },
        {
          DifficultyGroup: "Tough",
          DifficultyGroupId: 0,
          color: "#EF727A",
          isSelected: false,
        },
      ],
      selectedAllDiff: "",
      chapterList: [],
      conceptObject: {},
      demoArray: [],
      isFileUploaded: false,
      uploadedFileName: "",
      assignmentQuestions: false,
      checkbox1: true,
      isAssignmentQuestions: false,
      selctedClusterList: [],
      allManualQnsList: [],
      allAssignmentQnsList: [],
      errorMsg: "",
    };
  },
  mounted() {
    const user = this.$store.getters.user;
    API.getCourses({}, (course) => {
      this.Courses = course.data;
    });
    API.getBatches(user.AffiliationId, (batch) => {
      this.courseBatchList = batch.data;
    });
    MobileAPI.getAssignmentList(
      {
        FacultyId: user.UserId,
      },
      (response) => {
        this.getassignmentlist = response.data;
      },
    );
  },
  created() {
    this.currentDate = moment(String(new Date()))
      .utcOffset("-00:00")
      .add(330, "minutes")
      .toISOString();
  },
  activated() {
    this.selectCourse = null;
    this.Courses = [];
    this.classList = [];
    this.courseBatchList = [];
    this.listOfSubjectsForSelectedCourse = [];
    this.AffiliationBatch = [];
    this.teacherDashAssigData = this.$route.params.teacherDashAssigData;
    const batchReportCourseId = this.$route.params.teacherDashCourseId;
    if (this.teacherDashAssigData || batchReportCourseId) {
      this.teacherDashClassId = Number(this.$route.params.teacherDashClassId);
      this.selectCourse = Number(this.$route.params.teacherDashCourseId);
      this.batchId = Number(this.$route.params.teacherDashBatchId);
      this.$store.dispatch("showLoader", true);
      API.getCourses({}, (course) => {
        this.Courses = course.data;
        const filterSubject = this.Courses.filter((item) => item.courseId === this.selectCourse);
        this.listOfSubjectsForSelectedCourse = filterSubject[0].subjectList;
        this.selectSubject = Number(this.$route.params.teacherDashSubId);
        this.$store.dispatch("showLoader", false);
        this.$store.dispatch("showLoader", true);
        API.getBatches(this.$route.params.teacherDashAffilId, (batch) => {
          this.courseBatchList = batch.data;
          this.listOfBatchesForSelectedCourse = this.courseBatchList.filter(
            (item) => item.CourseId === Number(this.$route.params.teacherDashCourseId),
          );
          this.AffiliationBatch = this.listOfBatchesForSelectedCourse.filter(
            (item) => item.BatchId === this.batchId,
          );
          this.$store.dispatch("showLoader", false);
        });
        this.$store.dispatch("showLoader", true);
        MobileAPI.getCourseClass(this.selectCourse, (resp) => {
          this.classList = resp.data;
          this.selectClass = this.teacherDashClassId;
          this.$store.dispatch("showLoader", false);
          this.$store.dispatch("showLoader", true);
          MobileAPI.getcourseClassSubjectChapter(
            this.selectCourse,
            this.selectClass,
            (response) => {
              this.subjectChapterList = response.data;
              this.chapterlist = this.subjectChapterList.filter(
                (item) => item.SubjectId === this.selectSubject,
              );
              if (this.chapterlist.length > 0) {
                this.selectChapter = Number(this.$route.params.teacherDashCourseChapterId);
              }
              this.topidId = this.chapterlist.filter(
                (a) => a.CourseChapterId === this.selectChapter,
              );
              this.$store.dispatch("showLoader", false);
              this.$store.dispatch("showLoader", true);
              MobileAPI.getCourseChapterKscCluster(this.selectChapter, (KscResponse) => {
                this.kscClusterList = KscResponse.data;
                this.$store.dispatch("showLoader", false);
              });
            },
          );
        });
      });
      this.$store.dispatch("showLoader", false);
    } else {
      const user = this.$store.getters.user;
      API.getCourses({}, (course) => {
        this.Courses = course.data;
      });
      API.getBatches(user.AffiliationId, (batch) => {
        this.courseBatchList = batch.data;
      });
      MobileAPI.getAssignmentList(
        {
          FacultyId: user.UserId,
        },
        (response) => {
          this.getassignmentlist = response.data;
        },
      );
      API.getBatches(user.AffiliationId, (batch) => {
        this.courseBatchList = batch.data;
      });
    }
    if (this.$route.params.CourseId) {
      this.selectCourse = Number(this.$route.params.CourseId);
      this.batchId = Number(this.$route.params.BatchId);
      this.selectSubject = Number(this.$route.params.SubjectId);
      API.getCourses({}, (course) => {
        this.Courses = course.data;
      });
      API.getBatches(this.$route.params.teacherDashAffilId, (batch) => {
        this.courseBatchList = batch.data;
        this.listOfBatchesForSelectedCourse = this.courseBatchList.filter(
          (item) => item.CourseId === Number(this.$route.params.CourseId),
        );
        this.AffiliationBatch = this.listOfBatchesForSelectedCourse.filter(
          (item) => item.BatchId === Number(this.$route.params.BatchId),
        );
      });
    }
  },
  watch: {
    startDate(newVal) {
      this.assstartDate = moment(newVal).format("YYYY-MM-D HH:mm:ss");
    },
    endDate(newVal) {
      this.assendDate = moment(newVal).format("YYYY-MM-D HH:mm:ss");
    },
  },
  computed: {
    selectedClusterIdQuestions() {
      //   if (this.selectedCommonQuestionCluster.length === 0) {
      //     return this.commonQuestionClusterList;
      //   }
      //
      //
      return this.commonAssignmentKscQList.filter((item1) =>
        this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId),
      );
    },
  },
  methods: {
    // commonAssignmentQuestions() {
    // //   console.log("data", this.assignmentQuestions);
    //   this.commonAssignmentQuestionList = this.commonQuestionList.filter((item) => item.QuestionCategoryId === 3);
    //   this.commonQuestionList = this.commonAssignmentQuestionList;
    // },
    getSelectedEntityId(selectedentityId) {
      this.selctedClusterList = selectedentityId;
      this.selectedCommonQuestionCluster = selectedentityId;
      this.commonQuestionList = this.commonAssignmentKscQList.filter((item1) =>
        this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId),
      );
      if (this.commonQuestionList.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
      // this.commonQuestionList = this.commonQuestionClusterList.filter((item1) => this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId));
      this.diffLevelFilterByClusterId = this.commonAssignmentKscQList.filter((item1) =>
        this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId),
      );
    },
    getCommonQDiffLevel(diffleveldata) {
      this.commonQsDifficultyList = diffleveldata;
      this.democommonQsDifficultyList = diffleveldata;
      this.commonQuestionList = this.diffLevelFilterByClusterId.filter((item1) =>
        this.commonQsDifficultyList.some(
          (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
        ),
      );
      if (this.commonQuestionList.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
    },
    selectQns(questionData) {
      if (this.selectedCommonQuestions.includes(questionData.QuestionId)) {
        const index = this.selectedCommonQuestions.indexOf(questionData.QuestionId);
        if (index > -1) {
          this.selectedCommonQuestions.splice(index, 1);
        }
      } else {
        this.selectedCommonQuestions.push(questionData.QuestionId);
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }

      return value;
    },
    formatTime(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format(" hh:mm a");
      }

      return value;
    },

    onCourseChange(event) {
      this.selectCourse = Number(event.target.value);
      this.listOfBatchesForSelectedCourse = this.courseBatchList.filter(
        (a) => a.CourseId === this.selectCourse,
      );
      this.selectedBatches = [];
      this.classList = [];
      this.listOfSubjectsForSelectedCourse = [];
      this.AffiliationBatch = null;
      this.AffiliationKsc = [];
      this.selectAssignmentTypeId = 0;
      this.selectChapter = null;
      MobileAPI.getCourseClass(this.selectCourse, (resp) => {
        this.classList = resp.data;
      });
      for (let i = 0; i < this.Courses.length; i += 1) {
        if (this.Courses[i].courseId === Number(event.target.value)) {
          this.listOfSubjectsForSelectedCourse = this.Courses[i].subjectList;
          this.AffiliationCourseId = Number(this.Courses[i].affiliationCourseId);
          break;
        }
      }
    },
    onClassChange(event) {
      this.selectClass = Number(event.target.value);
      this.selectSubject = null;
      this.AffiliationKsc = [];
      this.selectAssignmentTypeId = 0;
      MobileAPI.getcourseClassSubjectChapter(this.selectCourse, this.selectClass, (response) => {
        this.subjectChapterList = response.data;
      });
    },
    onSubjectChange(event) {
      this.selectSubject = Number(event.target.value);
      this.chapterlist = this.subjectChapterList.filter((a) => a.SubjectId === this.selectSubject);
      this.selectedKSCes = [];
      this.AffiliationKsc = [];
      this.selectAssignmentTypeId = 0;
      this.uniqueChapterArray = this.chapterlist.map((item) => ({
        entityId: item.CourseChapterId,
        entityName: item.ChapterName,
      }));
    },
    onChapterChange(event) {
      this.selectChapter = Number(event.target.value);
      this.AffiliationKsc = null;
      this.selectedKSCes = [];
      this.AffiliationKsc = [];
      this.selectAssignmentTypeId = 0;
      this.topidId = this.chapterlist.filter((a) => a.CourseChapterId === this.selectChapter);
      MobileAPI.getCourseChapterKscCluster(this.selectChapter, (response) => {
        this.kscClusterList = response.data;
      });
    },
    onAssignmentChange(event) {
      this.selectAssignmentTypeId = Number(event.target.value);
      if (Number(event.target.value) === 1) {
        this.OpenCommonAssignment();
      }
      if (Number(event.target.value) === 2) {
        this.OpenUploadAssignment();
      }
      if (Number(event.target.value) === 3) {
        this.OpenAutoAssignment();
      }
    },
    onUploadFile(e) {
      this.file = e.target.files || e.dataTransfer.files;
      this.fileType = this.file.Type;
      if (this.uploadedFileName) {
        if (this.uploadedFileName !== e.target.files[0].name) {
          this.uploadedFileName = "";
          this.isFileUploaded = false;
          this.uploadedFileName = this.file[0].name;
        }
      } else {
        this.uploadedFileName = this.file[0].name;
      }
    },
    createStandardAssignment() {
      this.fileField1 = document.getElementById("file1");
      if (this.fileField1.files.length > 0) {
        this.isFileUploaded = true;
      }
      const chapterId = this.chapterlist.filter((a) => a.CourseChapterId === this.selectChapter);
      if (
        this.fileField1.files.length === 0 ||
        this.fileField1.files[0].type !== "application/pdf"
      ) {
        this.fileValidation = true;
        return;
      }
      this.fileValidation = false;
      if (this.selectSubject === null) {
        this.subjectValidation = true;
        return;
      }
      this.subjectValidation = false;
      if (chapterId.length === 0 || this.topidId[0].TopicId === null) {
        this.chapterValidation = true;
      }
      this.chapterValidation = false;
      const formData = new FormData();
      formData.append("file", this.fileField1.files[0]);
      formData.append("SubjectId", this.selectSubject);
      formData.append("TopicId", this.topidId[0].TopicId);
      formData.append("ChapterId", chapterId[0].ChapterId);
      formData.append("AssignmentTypeId", this.selectAssignmentTypeId);
      MobileAPI.createStandardAssignment(formData, (response) => {
        this.pdfassignmentId = response.data.PdfAssignmentId;
        this.showCampaignModal2 = false;
      });
    },
    OpenPublishModal(AssignmentLogId) {
      this.assignmentlogId = Number(AssignmentLogId);
      this.showCampaignModal = true;
      this.publishassignment = this.getassignmentlist.filter(
        (a) => a.AssignmentLogId === AssignmentLogId,
      );
      this.SelectedBatch = this.publishassignment[0].BatchDetails;
    },
    onSelectedBatchChange(event) {
      this.studentId = "";
      this.dataObj = {};
      this.selectBatchId = Number(event.target.value);
      const user = this.$store.getters.user;
      MobileAPI.getStudentForAssignment(
        {
          AffiliationId: user.AffiliationId,
          BatchIds: this.selectBatchId,
        },
        (response) => {
          this.studentList = response.data;
        },
      );
      this.StudentCsv = [];
    },
    StudentTag(newTag) {
      const tag = {
        Name: newTag,
        UserId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.studentList.push(tag);
      this.StudentCsv.push(tag);
    },
    selectStudentNone() {
      this.StudentCsv = [];
    },
    selectStudentAll() {
      this.StudentCsv = this.studentList;
      const student = this.StudentCsv.map((a) => a.UserId);
      this.studentId = String(student);
    },
    updateSelectedStudent(value) {
      if (this.StudentCsv.length >= 0) {
        this.selectedStudents = [];
      }
      value.forEach((resource) => {
        this.selectedStudents.push(resource);
      });
      const student = this.selectedStudents.map((a) => a.UserId);
      this.studentId = String(student);
      if (this.studentId !== "") {
        const data = {
          BatchId: this.selectBatchId,
          StudentIdCsv: this.studentId,
        };
        this.randomdata = data;
      }
      this.previousLength = this.selectedStudents.length;
    },
    onBatchSelect(event) {
      this.dataObj = {
        BatchId: Number(event.target.value),
        StudentIdCsv: this.studentId,
      };
      if (
        this.dataObj.StudentIdCsv !== "" &&
        this.dataObj.BatchId !== 0 &&
        this.dataObj.BatchId !== null
      ) {
        this.BatchStudentIdCsv.push(this.dataObj);
        this.dataObj = {};
        this.selectBatchId = null;
      }
      this.dataObj = {};
    },
    PublishTheAssignment() {
      const user = this.$store.getters.user;
      if (this.publishassignment[0].AssignmentType === "File") {
        if (this.selectClass === null) {
          this.batchValidation = true;
          return;
        }
        if (this.studentId.length === 0 && this.studentId === "") {
          this.studentValidation = true;
          return;
        }
        if (this.studentId !== "") {
          this.dataObj = {
            BatchId: this.selectBatchId,
            StudentIdCsv: this.studentId,
          };
          if (
            this.dataObj.StudentIdCsv !== "" &&
            this.dataObj.BatchId !== 0 &&
            this.dataObj.BatchId !== null
          ) {
            this.BatchStudentIdCsv.push(this.dataObj);
            this.dataObj = {};
            this.selectBatchId = null;
          }
        }
        const data = {
          AssignmentLogId: this.assignmentlogId,
          PdfAssignmentId: this.publishassignment[0].PdfAssignmentId,
          ExamTypeId: 2,
          StudentCsv: this.studentId,
          StartDate: this.assstartDate,
          EndDate: this.assendDate,
          BatchStudentIdCsv: this.BatchStudentIdCsv,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.publishAssignment(data, (response) => {
          this.publish = response.data;
          if (response.responseCode === 200) {
            window.M.toast({
              html: "Assignment Published Successfully",
              classes: "rounded success",
            });
            this.StudentCsv = [];
            this.selectClass = "";
            this.BatchStudentIdCsv = [];
          } else {
            window.M.toast({
              html: "Assignment Not Published",
              classes: "rounded success",
            });
            this.StudentCsv = [];
            this.selectClass = "";
            this.BatchStudentIdCsv = [];
          }
          this.showCampaignModal = false;
          MobileAPI.getAssignmentList(
            {
              FacultyId: user.UserId,
            },
            (response1) => {
              this.getassignmentlist = response1.data;
              this.$store.dispatch("showLoader", false);
            },
          );
        });
      } else {
        if (this.selectClass === null) {
          this.batchValidation = true;
          return;
        }
        if (this.studentId.length === 0 && this.studentId === "") {
          this.studentValidation = true;
          return;
        }
        if (this.studentId !== "") {
          this.dataObj = {
            BatchId: this.selectBatchId,
            StudentIdCsv: this.studentId,
          };
          if (
            this.dataObj.StudentIdCsv !== "" &&
            this.dataObj.BatchId !== 0 &&
            this.dataObj.BatchId !== null
          ) {
            this.BatchStudentIdCsv.push(this.dataObj);
            this.dataObj = {};
            this.selectBatchId = null;
          }
        }
        const data = {
          AssignmentLogId: this.assignmentlogId,
          autoQuestionCsv: this.publishassignment[0].QuestionIdsCsv,
          ExamTypeId: 2,
          StudentCsv: this.studentId,
          StartDate: this.assstartDate,
          EndDate: this.assendDate,
          BatchStudentIdCsv: this.BatchStudentIdCsv,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.publishAssignment(data, (response) => {
          this.publish = response.data;
          if (response.responseCode === 200) {
            window.M.toast({
              html: "Assignment Published Successfully",
              classes: "rounded success",
            });
            this.StudentCsv = [];
            this.selectClass = "";
            this.BatchStudentIdCsv = [];
          } else {
            window.M.toast({
              html: "Assignment Not Published",
              classes: "rounded success",
            });
            this.StudentCsv = [];
            this.selectClass = "";
            this.BatchStudentIdCsv = [];
          }
          this.showCampaignModal = false;
          MobileAPI.getAssignmentList(
            {
              FacultyId: user.UserId,
            },
            (response1) => {
              this.getassignmentlist = response1.data;
              this.$store.dispatch("showLoader", false);
            },
          );
        });
      }
    },
    OpenAutoAssignment() {
      this.isFileUploaded = false;
      this.showCampaignModal3 = true;
    },
    OpenCommonAssignment() {
      this.isFileUploaded = false;
      this.commonQuestionClusterList = [];
      this.allManualQnsList = [];
      this.allAssignmentQnsList = [];
      const data1 = {
        courseId: this.selectCourse,
        batches: this.batchId,
        courseChapterId: this.selectChapter,
        questionType: 1,
        questionCategoryId: 3,
      };
      const data2 = {
        courseId: this.selectCourse,
        batches: this.batchId,
        courseChapterId: this.selectChapter,
        questionType: 1,
        questionCategoryId: 1,
      };
      let QuestionList = [];
      this.selectedClusters =
        this.AffiliationKsc.length > 0 ? this.AffiliationKsc : this.selectedKSCes;
      this.$store.dispatch("showLoader", true);
      MobileAPI.getCreatTestManualQuestion(data1, (response) => {
        if (response.data.length > 0 && response.responseCode === 200) {
          this.allAssignmentQnsList = response.data.filter((item1) =>
            this.selectedClusters.some((item) => item.KSCClusterId === item1.KSCClusterId),
          );
          this.$store.dispatch("showLoader", false);
        }
        this.$store.dispatch("showLoader", true);
        MobileAPI.getCreatTestManualQuestion(data2, (resp) => {
          if (resp.data.length > 0 && resp.responseCode === 200) {
            this.allManualQnsList = resp.data.filter((item1) =>
              this.selectedClusters.some((item) => item.KSCClusterId === item1.KSCClusterId),
            );
            this.$store.dispatch("showLoader", false);
          }
          if (this.allAssignmentQnsList.length > 0) {
            QuestionList = this.allAssignmentQnsList;
            this.commonQuestionList = this.allAssignmentQnsList;
            this.commonAssignmentKscQList = this.commonQuestionList;
            this.diffLevelFilterByClusterId = this.commonQuestionList;
            this.checkbox1 = true;
            this.showCommonAssignmentModal = true;
          } else if (this.allManualQnsList.length > 0) {
            QuestionList = this.allManualQnsList;
            this.commonQuestionList = this.allManualQnsList;
            this.commonAssignmentKscQList = this.commonQuestionList;
            this.diffLevelFilterByClusterId = this.commonQuestionList;
            this.checkbox1 = false;
            this.showCommonAssignmentModal = true;
          }
          if (QuestionList.length > 0) {
            this.selectedClusters =
              this.AffiliationKsc.length > 0 ? this.AffiliationKsc : this.selectedKSCes;
            this.commonQuestionClusterList = QuestionList.filter((item1) =>
              this.selectedClusters.some((item) => item.KSCClusterId === item1.KSCClusterId),
            );
            this.showCommonAssignmentModal = true;
            this.$store.dispatch("showLoader", false);
          } else {
            this.showCommonAssignmentModal = false;
            window.M.toast({
              html: "Question Not found",
            });
            this.$store.dispatch("showLoader", false);
          }
        });
      });

      this.conceptObject = {
        Name: "Concept", // Component Title
        entityArray: this.uniqueClusterArray, // array
      };
    },
    closeAssignmentPopop() {
      this.showCommonAssignmentModal = false;
      this.commonQuestionList = [];
      this.allManualQnsList = [];
      this.allAssignmentQnsList = [];
    },
    toggleCheckbox1() {
      this.checkbox1 = !this.checkbox1;
      if (!this.checkbox1) {
        this.commonQuestionList = this.allManualQnsList;
        // this.commonQuestionList =
        this.commonQuestionClusterList = this.commonQuestionList;
        this.diffLevelFilterByClusterId = this.commonQuestionClusterList;
        this.commonAssignmentKscQList = this.commonQuestionClusterList;
        // this.isAssignmentQuestions = true;
        this.getSelectedEntityId(this.selctedClusterList);
      } else {
        // const commonQuestionList = this.commonQuestionClusterList.filter((item) => item.QuestionCategoryId === 3);
        this.commonQuestionList = this.allAssignmentQnsList;
        this.commonQuestionClusterList = this.commonQuestionList;
        this.diffLevelFilterByClusterId = this.commonQuestionClusterList;
        this.commonAssignmentKscQList = this.commonQuestionClusterList;

        // this.diffLevelFilterByClusterId = this.commonQuestionList;
        // this.commonQuestionList = this.commonQuestionList;
      }
      this.isAssignmentQuestions = true;
    },
    OpenUploadAssignment() {
      this.showCampaignModal2 = true;
    },
    BatchTag(newTag) {
      const tag = {
        BatchName: newTag,
        BatchId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.listOfBatchesForSelectedCourse.push(tag);
      this.AffiliationBatch.push(tag);
    },
    selectBatchNone() {
      this.AffiliationBatch = [];
    },
    selectBatchAll() {
      this.AffiliationBatch = this.listOfBatchesForSelectedCourse;
      const batch = this.AffiliationBatch.map((a) => a.BatchId);
      this.batchId = String(batch);
      this.SubjectValidation = false;
    },
    updateSelectedBatch(value) {
      if (this.AffiliationBatch.length >= 0) {
        this.selectedBatches = [];
      }
      value.forEach((resource) => {
        this.selectedBatches.push(resource);
      });
      const batch = this.selectedBatches.map((a) => a.BatchId);
      this.batchId = String(batch);
      this.SubjectValidation = false;
    },
    KscTag(newTag) {
      const tag = {
        KSCClusterName: newTag,
        KSCClusterId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.kscClusterList.push(tag);
      this.AffiliationKsc.push(tag);
    },
    selectKscNone() {
      this.AffiliationKsc = [];
    },
    selectKscAll() {
      this.AffiliationKsc = this.kscClusterList;
      this.KSC = this.AffiliationKsc.map((a) => a.KSCClusterId);
      this.KSCId = String(this.KSC);
      this.uniqueClusterArray = this.AffiliationKsc.map((item) => ({
        entityId: item.KSCClusterId,
        entityName: item.KSCClusterName,
      }));
    },
    updateSelectedKsc(value) {
      if (this.AffiliationKsc.length >= 0) {
        this.selectedKSCes = [];
      }
      value.forEach((resource) => {
        this.selectedKSCes.push(resource);
      });
      if (this.selectedKSCes.length > 1) {
        this.hideALL = true;
      }
      //   if (this.selectedKSCes.length < 2) {
      //     this.commonQsclusterId = this.selectedKSCes.KSCClusterId;
      //   }
      this.uniqueClusterArray = this.selectedKSCes.map((item) => ({
        entityId: item.KSCClusterId,
        entityName: item.KSCClusterName,
      }));
      this.KSC = this.selectedKSCes.map((a) => a.KSCClusterId);
      this.KSCId = String(this.KSC);
      this.SubjectValidation = false;
    },
    toggleQuestion(questionId) {
      if (this.selectedCommonQuestions.includes(questionId)) {
        const index = this.selectedCommonQuestions.indexOf(questionId);
        if (index > -1) {
          this.selectedCommonQuestions.splice(index, 1);
        }
      } else {
        this.selectedCommonQuestions.push(questionId);
      }
    },
    cancelPublish() {
      this.showCampaignModal = false;
      this.showCampaignModal5 = false;
      this.showCampaignModal2 = false;
      this.showCampaignModal6 = false;
      this.StudentCsv = [];
      this.selectClass = "";
      this.isFileUploaded = false;
      this.uploadedFileName = "";
    },
    CreateAssignment() {
      if (this.selectCourse === null || this.selectCourse === "") {
        this.courseValidation = true;
        return;
      }
      this.courseValidation = false;

      if (this.batchId === "" || this.AffiliationBatch === null) {
        this.batchValidation = true;
        return;
      }
      this.batchValidation = false;
      if (this.selectClass === "" || this.selectClass === null) {
        this.classValidation = true;
        return;
      }
      this.classValidation = false;
      if (this.selectSubject === null) {
        this.subjectValidation = true;
        return;
      }
      this.subjectValidation = false;
      if (this.selectChapter === "" || this.selectChapter === null) {
        this.chapterValidation = true;
        return;
      }
      this.chapterValidation = false;
      if (this.AffiliationKsc === null || this.AffiliationKsc.length === 0) {
        this.clusterValidation = true;
        return;
      }
      this.clusterValidation = false;
      if (this.selectAssignmentTypeId === -1 || this.selectAssignmentTypeId === null) {
        this.assignmentValidation = true;
        return;
      }
      this.assignmentValidation = false;
      if (this.selectedCommonQuestions.length === 0 && this.selectAssignmentTypeId === 1) {
        this.commonAssiValidation = true;
        return;
      }
      this.commonAssiValidation = false;

      if (
        (this.pdfassignmentId === 0 && this.selectAssignmentTypeId === 2) ||
        (this.pdfassignmentId === null && this.selectAssignmentTypeId === 2)
      ) {
        this.fileValidation = true;
        return;
      }
      this.fileValidation = false;

      if (this.selectAssignmentTypeId === 2) {
        if (this.selectCourse === null || this.selectCourse === "") {
          this.courseValidation = true;
          return;
        }
        if (this.batchId === "" || this.AffiliationBatch === null) {
          this.batchValidation = true;
          return;
        }
        if (this.selectClass === "" || this.selectClass === null) {
          this.classValidation = true;
          return;
        }
        if (this.selectSubject === null) {
          this.subjectValidation = true;
          return;
        }
        if (this.selectChapter === "" || this.selectChapter === null) {
          this.chapterValidation = true;
          return;
        }
        if (this.KSC.length === 0 || this.selectedKSCes.length === 0) {
          this.clusterValidation = true;
          return;
        }
        const user = this.$store.getters.user;
        const newData = [];
        this.KSC.forEach((value) => {
          newData.push({
            ChapterId: this.selectChapter,
            ClusterId: value,
          });
        });
        const data = {
          CourseId: this.selectCourse,
          TopicId: this.topidId[0].TopicId,
          BatchIdsCsv: this.batchId,
          AssignmentTypeId: 2,
          FacultyId: user.UserId,
          SubjectId: this.selectSubject,
          PdfAssignmentId: this.pdfassignmentId,
          ClassId: this.selectClass,
          ChapterClusterKsc: newData,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.addAssignment(data, (response) => {
          this.addedAssignment = response.data;
          if (response.responseCode === 200) {
            window.M.toast({
              html: "Assignment Created Successfully",
              classes: "rounded success",
            });
          }
          MobileAPI.getAssignmentList(
            {
              FacultyId: user.UserId,
            },
            (response1) => {
              this.getassignmentlist = response1.data;
              this.$store.dispatch("showLoader", false);
            },
          );
        });
      } else if (this.selectAssignmentTypeId === 1) {
        if (this.selectCourse === null || this.selectCourse === "") {
          this.courseValidation = true;
          return;
        }
        if (this.batchId === "" || this.AffiliationBatch === null) {
          this.batchValidation = true;
          return;
        }
        if (this.selectClass === "" || this.selectClass === null) {
          this.classValidation = true;
          return;
        }
        if (this.selectSubject === null) {
          this.subjectValidation = true;
          return;
        }
        if (this.selectChapter === "" || this.selectChapter === null) {
          this.chapterValidation = true;
          return;
        }
        if (this.KSC.length === 0 || this.KSC === null || this.selectedKSCes.length === 0) {
          this.clusterValidation = true;
          return;
        }
        if (this.selectedCommonQuestions.length === 0) {
          this.commonAssiValidation = true;
          return;
        }
        this.commonAssiValidation = false;
        const user = this.$store.getters.user;
        const newData = [];
        this.KSC.forEach((value) => {
          newData.push({
            ChapterId: this.selectChapter,
            ClusterId: value,
          });
        });
        const data = {
          CourseId: this.selectCourse,
          TopicId: this.topidId[0].TopicId,
          BatchIdsCsv: this.batchId,
          AssignmentTypeId: 1,
          FacultyId: user.UserId,
          SubjectId: this.selectSubject,
          QuestionIdCsv: this.selectedCommonQuestions,
          ClassId: this.selectClass,
          ChapterClusterKsc: newData,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.addAssignment(data, (response) => {
          this.addedAssignment = response.data;
          if (response.responseCode === 200) {
            window.M.toast({
              html: "Assignment Created Successfully",
              classes: "rounded success",
            });
          }
          MobileAPI.getAssignmentList(
            {
              FacultyId: user.UserId,
            },
            (response1) => {
              this.getassignmentlist = response1.data;
              this.$store.dispatch("showLoader", false);
            },
          );
        });
      } else if (this.selectAssignmentTypeId === 3) {
        if (this.selectCourse === null || this.selectCourse === "") {
          this.courseValidation = true;
          return;
        }
        if (this.batchId === "" || this.AffiliationBatch === null) {
          this.batchValidation = true;
          return;
        }
        if (this.selectClass === "" || this.selectClass === null) {
          this.classValidation = true;
          return;
        }
        if (this.selectSubject === null) {
          this.subjectValidation = true;
          return;
        }
        if (this.selectChapter === "" || this.selectChapter === null) {
          this.chapterValidation = true;
          return;
        }
        if (this.KSC.length === 0 || this.KSC === null || this.selectedKSCes.length === 0) {
          this.clusterValidation = true;
          return;
        }
        const user = this.$store.getters.user;
        const newData = [];
        this.KSC.forEach((value) => {
          newData.push({
            ChapterId: this.selectChapter,
            ClusterId: value,
          });
        });
        const data = {
          CourseId: this.selectCourse,
          TopicId: this.topidId[0].TopicId,
          BatchIdsCsv: this.batchId,
          AssignmentTypeId: 3,
          FacultyId: user.UserId,
          SubjectId: this.selectSubject,
          QuestionIdCsv: this.selectedCommonQuestions,
          ClassId: this.selectClass,
          ChapterClusterKsc: newData,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.addAssignment(data, (response) => {
          this.addedAssignment = response.data;
          if (response.responseCode === 200) {
            window.M.toast({
              html: "Assignment Created Successfully",
              classes: "rounded success",
            });
          }
          MobileAPI.getAssignmentList(
            {
              FacultyId: user.UserId,
            },
            (response1) => {
              this.getassignmentlist = response1.data;
              this.$store.dispatch("showLoader", false);
            },
          );
        });
      }
      this.selectedKSCes = [];
      this.selectCourse = null;
      this.AffiliationBatch = null;
      this.selectSubject = null;
      this.selectChapter = null;
      this.selectClass = "";
      this.selectAssignmentTypeId = null;
      this.AffiliationKsc = null;
      this.selectedCommonQuestions = [];
    },
    goToModal() {
      if (this.selectAssignmentTypeId === 1) {
        this.isFileUploaded = false;
        this.OpenCommonAssignment();
      }
      if (this.selectAssignmentTypeId === 2) {
        this.OpenUploadAssignment();
      }
      if (this.selectAssignmentTypeId === 3) {
        this.isFileUploaded = false;
        this.OpenAutoAssignment();
      }
    },
    onViewAssignment(AssignmentLogId) {
      this.assignmentlogId = Number(AssignmentLogId);
      this.publishassignment = this.getassignmentlist.filter(
        (a) => a.AssignmentLogId === AssignmentLogId,
      );
      this.PdfId = this.publishassignment[0].PdfAssignmentId;
      if (this.publishassignment[0].PdfUrl != null) {
        MobileAPI.getAssignmentUrl(this.PdfId, (response) => {
          this.pdfURL = response.data.FileURL;
          this.fileContent = response.data.FileContent;
          this.showCampaignModal4 = true;
        });
        // this.showCampaignModal4 = false;
      } else {
        this.pdfURL = "";
        MobileAPI.getAssignmentQuestion(
          {
            AssignmentLogId: this.assignmentlogId,
          },
          (response) => {
            this.questionURL = response.data;
            this.showCampaignModal4 = true;
          },
        );
      }
    },
    OpenRescheduleModal(AssignmentLogId) {
      this.showCampaignModal5 = true;
      this.assignmentlogId = Number(AssignmentLogId);
      this.publishassignment = this.getassignmentlist.filter(
        (a) => a.AssignmentLogId === AssignmentLogId,
      );
    },
    RescheduleAssignment() {
      const data = {
        AssignmentLogId: this.assignmentlogId,
        StartDate: this.assstartDate,
        EndDate: this.assendDate,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.rescheduleAssignment(data, (response) => {
        this.reschedule = response.data;
        if (response.responseCode === 200) {
          window.M.toast({
            html: "Assignment Rescheduled ",
            classes: "rounded success",
          });
        }
        this.showCampaignModal5 = false;
        this.$store.dispatch("showLoader", false);
      });
    },
    cancelModal(AssignmentLogId) {
      this.showCampaignModal6 = true;
      this.cancelAssignId = AssignmentLogId;
    },
    cancelassign(AssignmentLogId) {
      this.$store.dispatch("showLoader", true);
      const user = this.$store.getters.user;
      MobileAPI.cancelAssignment(AssignmentLogId, (response) => {
        this.cancelAssignment = response.data;
        if (response.responseCode === 200) {
          window.M.toast({
            html: "Assignment Canceled Successfully",
            classes: "rounded success",
          });
        }
        this.showCampaignModal6 = false;
        MobileAPI.getAssignmentList(
          {
            FacultyId: user.UserId,
          },
          (response1) => {
            this.getassignmentlist = response1.data;
            this.$store.dispatch("showLoader", false);
          },
        );
      });
    },
  },
  components: {
    CommonFilterComponent,
    Modal,
    VueMultiselect,
    ManualQuestion,
    DifficultLevelComponent,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

.assignment {
  font-family: "Open Sans" !important;
}

.heading {
  margin-top: 20px;
  /* font-family: Open Sans;
          font-style: normal; */
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  color: #333333;
  width: 247px;
  height: 25px;
}

.dark-text {
  color: #333333;
}

.filterSection {
  width: 265px;
  border-radius: 4px;
  border: 1px solid #e0e4f0;
  /* padding-left: 15px; */
  padding-top: 5px;
  padding-bottom: 35px;
  height: 525px;
}

.batch-button {
  width: 194px;
  height: 35px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: #ffffff;
}

.publish-button {
  background-color: green;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  height: 25px;
  width: 75px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}

.published-button {
  background-color: silver;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  height: 25px;
  width: 95px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}

.active {
  border: 1px solid #0071c5;
}

.questions-container {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ededed80;
  background-color: #f6f6f6;
}

.questions-container .card {
  cursor: pointer;
}

.questions-container .card .card-image {
  border-left: 8px solid #9e9e9e;
  color: #9e9e9e;
}

.questions-container .card.selected .card-image {
  border-left: 8px solid #4caf50;
  color: #4caf50;
}

.questions-container .card i {
  display: none;
}

.questions-container .card.selected i {
  display: inline-block;
  position: absolute;
  color: #4caf50;
  right: 0px;
  bottom: 0;
  padding: 10px;
}

.reschedule-button {
  background-color: orange;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  height: 25px;
  width: 95px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}

.assignment-modal-body {
  height: 420px;
  width: 550px !important;
  /* overflow-x: scroll; */
  /* overflow-y: scroll; */
}

.reschedule-modal-body {
  height: 250px;
  width: 450px !important;
}

.cancel-modal-body {
  height: 150px !important;
  width: 400px !important;
  overflow-x: none !important;
  overflow-y: none !important;
}

.cancel-button {
  height: 25px;
  width: 95px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  color: black;
  border: none;
}

.button-row {
  margin-top: 150px;
}

.auto-modal-body {
  height: 160px !important;
  width: 400px;
  /* overflow: hidden !important; */
}

table.qtbl {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}

table.qtbl thead tr {
  background-color: #6a2f85;
  color: #fff;
  border-radius: 5px !important;
}

table.qtbl thead tr th {
  border-radius: none;
}

table.qtbl thead tr td:first-child,
table.qtbl tbody tr td:first-child {
  border-radius: 5px 0 0 5px;
}

table.qtbl tbody tr {
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
}

.document-upload-header {
  background-color: #642c90;
  color: #ffffff;
  height: 35px;
  display: flex;
  justify-content: space-between;
}

.multiselectBtn {
  width: 90px;
  height: 35px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.browser-default {
  border-radius: 5px;
  height: 40px;
  border: 1px solid #e3e3e3;
}

.modal-body {
  overflow-y: none !important;
}

.ques-btn {
  height: 40px;
  border: 1px solid #37a8d7;
  color: white;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  background: #37a8d7;
}

.loadMoreQuestionsButton {
  width: 165px;
  height: 30px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: #ffffff;
  margin-right: 15px;
}

.loadMoreQuestionsButton.active {
  width: 125px;
  height: 30px;
  background: linear-gradient(90.2deg, #f1f1f1 0.16%, #f1f1f1 98.41%) !important;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #dbdbdb;
  color: #8e8e8e;
  margin-right: 15px;
}

.scroll-verticle-bar {
  max-height: calc(100vh - 200px);
  min-height: 500px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px;
  margin: 0 10px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3f3f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: #4787f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f3f3f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f3f3f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(22px);
  background-color: #fcbc5c;
}

.slider.round {
  border-radius: 34px;
}

.increaseHeight {
  height: 105px;
}

.slider.round:before {
  border-radius: 50%;
}

::-webkit-scrollbar {
  display: none;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
